import { signOut } from "firebase/auth";
import { authentication } from "../firebase";
import { actionTypes } from "../reducer";
import { useStateValue } from "../StateProvider";

function Sidebar({ mobile = false }) {
  const [{ user, tab }, dispatch] = useStateValue();

  const signoutUser = () => {
    signOut(authentication).then(() => {
      dispatch({
        type: actionTypes.SET_USER,
        user: null,
      });
      localStorage.setItem("user", null);
    });
  };

  return (
    <div
      className={`bg-white px-5 border-r-[1px] border-gray-100/80 ${
        mobile ? "flex flex-1 sm:hidden flex-col" : "hidden sm:flex flex-col"
      } relative sm:static`}
    >
      <div className="flex-1 absolute bg-white z-10 h-auto sm:h-0 w-auto sm:w-0"></div>
      <div className="flex flex-row justify-center items-center w-16 mx-auto my-4 sm:mt-6 p-2 bg-white rounded-2xl text-white z-20">
        <img
          src={require("../assets/logo.png")}
          loading="lazy"
          className="h-full w-full object-cover"
        />
        <p className="text-black relative sm:hidden ml-2 text-xl font-bold text-[#1ab394] cursor-pointer">
          OpinionTrade
        </p>
      </div>
      <nav
        className={`relative flex ${
          mobile ? "flex-row" : "flex-col"
        } py-2 sm:py-4 justify-around px-10 sm:px-0 z-20`}
      >
        <a
          onClick={() =>
            dispatch({
              type: actionTypes.SET_TAB,
              tab: 1,
            })
          }
          className={`relative flex justify-center items-center w-16 h-16 p-4 ${
            tab === 1
              ? "bg-[#1ab394]"
              : "border hover:bg-[#d0f5ed] hover:border-[#d0f5ed]"
          } text-purple-900 rounded-2xl mb-4 justify-center items-center cursor-pointer`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            fill={tab === 1 ? "#0d5446" : "#b3b7ba"}
            className="flex-1"
          >
            <path d="M384 319.1C384 425.9 297.9 512 192 512s-192-86.13-192-192c0-58.67 27.82-106.8 54.57-134.1C69.54 169.3 96 179.8 96 201.5v85.5c0 35.17 27.97 64.5 63.16 64.94C194.9 352.5 224 323.6 224 288c0-88-175.1-96.12-52.15-277.2c13.5-19.72 44.15-10.77 44.15 13.03C215.1 127 384 149.7 384 319.1z" />
          </svg>
        </a>
        <a
          onClick={() =>
            dispatch({
              type: actionTypes.SET_TAB,
              tab: 2,
            })
          }
          className={`flex justify-center items-center w-16 h-16 p-4 ${
            tab === 2
              ? "bg-[#1ab394]"
              : "border hover:bg-[#d0f5ed] hover:border-[#d0f5ed]"
          } text-gray-700 rounded-2xl mb-4 items-center cursor-pointer`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            fill={tab === 2 ? "#0d5446" : "#b3b7ba"}
            className="flex-1"
          >
            <path d="M240.5 224H352C365.3 224 377.3 232.3 381.1 244.7C386.6 257.2 383.1 271.3 373.1 280.1L117.1 504.1C105.8 513.9 89.27 514.7 77.19 505.9C65.1 497.1 60.7 481.1 66.59 467.4L143.5 288H31.1C18.67 288 6.733 279.7 2.044 267.3C-2.645 254.8 .8944 240.7 10.93 231.9L266.9 7.918C278.2-1.92 294.7-2.669 306.8 6.114C318.9 14.9 323.3 30.87 317.4 44.61L240.5 224z" />
          </svg>
        </a>
        <a
          onClick={() =>
            dispatch({
              type: actionTypes.SET_TAB,
              tab: 3,
            })
          }
          className={`flex justify-center items-center w-16 h-16 p-4 ${
            tab === 3
              ? "bg-[#1ab394]"
              : "border hover:bg-[#d0f5ed] hover:border-[#d0f5ed]"
          } text-gray-700 rounded-2xl mb-4 items-center cursor-pointer`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill={tab === 3 ? "#0d5446" : "#b3b7ba"}
            className="flex-1"
          >
            <path d="M0 219.2v212.5c0 14.25 11.62 26.25 26.5 27C75.32 461.2 180.2 471.3 240 511.9V245.2C181.4 205.5 79.99 194.8 29.84 192C13.59 191.1 0 203.6 0 219.2zM482.2 192c-50.09 2.848-151.3 13.47-209.1 53.09C272.1 245.2 272 245.3 272 245.5v266.5c60.04-40.39 164.7-50.76 213.5-53.28C500.4 457.9 512 445.9 512 431.7V219.2C512 203.6 498.4 191.1 482.2 192zM352 96c0-53-43-96-96-96S160 43 160 96s43 96 96 96S352 149 352 96z" />
          </svg>
        </a>
        {user ? (
          <a
            onClick={() =>
              dispatch({
                type: actionTypes.SET_TAB,
                tab: 4,
              })
            }
            className={`flex justify-center items-center w-16 h-16 p-4 ${
              tab === 4
                ? "bg-[#1ab394]"
                : "border hover:bg-[#d0f5ed] hover:border-[#d0f5ed]"
            } text-gray-700 rounded-2xl mb-4 items-center cursor-pointer`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              fill={tab === 4 ? "#0d5446" : "#b3b7ba"}
              className="flex-1"
            >
              <path d="M48 0H336C362.5 0 384 21.49 384 48V487.7C384 501.1 373.1 512 359.7 512C354.7 512 349.8 510.5 345.7 507.6L192 400L38.28 507.6C34.19 510.5 29.32 512 24.33 512C10.89 512 0 501.1 0 487.7V48C0 21.49 21.49 0 48 0z" />
            </svg>
          </a>
        ) : null}
        {user ? (
          <a
            onClick={() =>
              dispatch({
                type: actionTypes.SET_TAB,
                tab: 5,
              })
            }
            className={`flex justify-center items-center w-16 h-16 p-4 ${
              tab === 5
                ? "bg-[#1ab394]"
                : "border hover:bg-[#d0f5ed] hover:border-[#d0f5ed]"
            } text-gray-700 rounded-2xl mb-4 items-center cursor-pointer`}
          >
            <svg
              fill={tab === 5 ? "#0d5446" : "#b3b7ba"}
              viewBox="0 0 32.219 32.219"
              className="flex-1"
            >
              <g>
                <path
                  d="M32.144,12.402c-0.493-1.545-3.213-1.898-6.09-2.277c-1.578-0.209-3.373-0.445-3.914-0.844
		c-0.543-0.398-1.304-2.035-1.978-3.482C18.94,3.17,17.786,0.686,16.166,0.68l-0.03-0.003c-1.604,0.027-2.773,2.479-4.016,5.082
		c-0.684,1.439-1.463,3.07-2.005,3.463c-0.551,0.394-2.342,0.613-3.927,0.803c-2.877,0.352-5.598,0.68-6.108,2.217
		c-0.507,1.539,1.48,3.424,3.587,5.424c1.156,1.094,2.465,2.34,2.67,2.98c0.205,0.639-0.143,2.414-0.448,3.977
		c-0.557,2.844-1.084,5.535,0.219,6.5c0.312,0.225,0.704,0.338,1.167,0.328c1.331-0.023,3.247-1.059,5.096-2.062
		c1.387-0.758,2.961-1.611,3.661-1.621c0.675,0.002,2.255,0.881,3.647,1.654c1.891,1.051,3.852,2.139,5.185,2.119
		c0.414-0.01,0.771-0.117,1.06-0.322c1.312-0.947,0.814-3.639,0.285-6.494c-0.289-1.564-0.615-3.344-0.409-3.982
		c0.213-0.639,1.537-1.867,2.702-2.955C30.628,15.808,32.634,13.945,32.144,12.402z M21.473,19.355h-3.722v3.797h-3.237v-3.797
		h-3.768v-3.238h3.768v-3.691h3.237v3.691h3.722V19.355z"
                />
              </g>
            </svg>
          </a>
        ) : null}

        <a
          onClick={() => {
            dispatch({
              type: actionTypes.SET_SEARCH,
              enabled: true,
            });
          }}
          className={`flex justify-center items-center w-16 h-16 p-4 ${
            tab === 6
              ? "bg-[#1ab394]"
              : "border hover:bg-[#d0f5ed] hover:border-[#d0f5ed]"
          } text-gray-700 rounded-2xl mb-4 items-center cursor-pointer`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill={tab === 6 ? "#0d5446" : "#b3b7ba"}
            className="flex-1"
          >
            <path d="M500.3 443.7l-119.7-119.7c27.22-40.41 40.65-90.9 33.46-144.7C401.8 87.79 326.8 13.32 235.2 1.723C99.01-15.51-15.51 99.01 1.724 235.2c11.6 91.64 86.08 166.7 177.6 178.9c53.8 7.189 104.3-6.236 144.7-33.46l119.7 119.7c15.62 15.62 40.95 15.62 56.57 0C515.9 484.7 515.9 459.3 500.3 443.7zM79.1 208c0-70.58 57.42-128 128-128s128 57.42 128 128c0 70.58-57.42 128-128 128S79.1 278.6 79.1 208z" />
          </svg>
        </a>
      </nav>
      {user ? (
        <div
          className="w-16 mx-auto p-4 bg-white rounded-2xl text-white hover:bg-[#ffa7a1] cursor-pointer"
          onClick={() => signoutUser()}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            fill="#f44336"
          >
            <path d="M160 416H96c-17.67 0-32-14.33-32-32V128c0-17.67 14.33-32 32-32h64c17.67 0 32-14.33 32-32S177.7 32 160 32H96C42.98 32 0 74.98 0 128v256c0 53.02 42.98 96 96 96h64c17.67 0 32-14.33 32-32S177.7 416 160 416zM502.6 233.4l-128-128c-12.51-12.51-32.76-12.49-45.25 0c-12.5 12.5-12.5 32.75 0 45.25L402.8 224H192C174.3 224 160 238.3 160 256s14.31 32 32 32h210.8l-73.38 73.38c-12.5 12.5-12.5 32.75 0 45.25s32.75 12.5 45.25 0l128-128C515.1 266.1 515.1 245.9 502.6 233.4z" />
          </svg>
        </div>
      ) : null}
    </div>
  );
}

export default Sidebar;
