export const initialState = {
  user: null,
  name: "",
  thumbnail: "",
  tab: 1,
  chat: {
    enabled: false,
    data: [],
  },
  config: {},
  selectedstocks: [],
  send: {
    enabled: false,
    data: [],
    selected: [],
    comment: "",
  },
  search: {
    enabled: false,
  },
  sharednews: {
    newsTitle: "",
    newsLink: "",
    newsSite: "",
  },
  loginClicked: false
};

export const actionTypes = {
  SET_USER: "SET_USER",
  SET_NAME: "SET_NAME",
  SET_THUMBNAIL: "SET_THUMBNAIL",
  SET_TAB: "SET_TAB",
  SET_CHAT: "SET_CHAT",
  SET_CONFIG: "SET_CONFIG",
  SET_SELECTED_STOCK: "SET_SELECTED_STOCK",
  DELETE_SELECTED_STOCK: "DELETE_SELECTED_STOCK",
  CLEAR_SELECTED_STOCK: "CLEAR_SELECTED_STOCK",
  SEND_NEWS: "SEND_NEWS",
  SET_SEARCH: "SET_SEARCH",
  SET_SHARED_NEWS: "SET_SHARED_NEWS",
  SET_COMMENT: "SET_COMMENT",
  SET_LOGIN_CLICKED: "SET_LOGIIN_CLICKED"
};

const reducer = (state, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case actionTypes.SET_NAME:
      return {
        ...state,
        name: action.name,
      };

    case actionTypes.SET_THUMBNAIL:
      return {
        ...state,
        thumbnail: action.thumbnail,
      };

    case actionTypes.SET_TAB:
      return {
        ...state,
        tab: action.tab,
      };

    case actionTypes.SET_CHAT:
      return {
        ...state,
        chat: {
          enabled: action.chatenabled,
          data: action.chatdata,
        },
      };

    case actionTypes.SET_CONFIG:
      return {
        ...state,
        config: action.config,
      };

    case actionTypes.SET_SELECTED_STOCK:
      return {
        ...state,
        selectedstocks: [...state.selectedstocks, action.stock],
      };

    case actionTypes.DELETE_SELECTED_STOCK:
      let newArr = state.selectedstocks.filter((e) => e !== action.stock);
      return {
        ...state,
        selectedstocks: newArr,
      };

    case actionTypes.CLEAR_SELECTED_STOCK:
      return {
        ...state,
        selectedstocks: [],
      };

    case actionTypes.SEND_NEWS:
      return {
        ...state,
        send: {
          enabled: action.sendenabled,
          data: action.news,
          selected: action.room || state.send.selected,
          comment: state.send.comment,
        },
      };

    case actionTypes.SET_SEARCH:
      return {
        ...state,
        search: {
          enabled: action.enabled,
        },
      };

    case actionTypes.SET_SHARED_NEWS:
      return {
        ...state,
        sharednews: action.news,
      };

    case actionTypes.SET_COMMENT:
      return {
        ...state,
        send: {
          ...state.send,
          comment: action.comment,
        },
      };

    case actionTypes.SET_LOGIN_CLICKED:
      return {
        ...state,
        loginClicked: action.loginClicked,
      };

    default:
      return state;
  }
};

export default reducer;
