import NewsStockSlider from "./NewsStockSlider";
import { ref, set, update } from "firebase/database";
import moment from "moment";
import { database } from "../firebase";
import { actionTypes } from "../reducer";
import { useStateValue } from "../StateProvider";
import { useEffect, useState } from "react";
import AnimatedGradientView from "./AnimatedGradientView";

function SharedNewsCard({ news, bookmarknews, readnews, storedsymbols, chat }) {
  const [{ send, user }, dispatch] = useStateValue();
  let changedNewsTitle = news.newsTitle.replace(/[^A-Z0-9]+/gi, "");
  let isBookmarked = bookmarknews.includes(changedNewsTitle);
  let isRead = readnews.includes(changedNewsTitle);
  let sendSelectedNews = send.enabled && send.data.newsTitle === news.newsTitle;
  const [enableAI, setenableAI] = useState(false);
  const [summary, setsummary] = useState("");
  const [loading, setloading] = useState(false);
  const isChat = chat === true;

  const fetchLinkData = () => {

    if (news && news.aisummary && user){
        setsummary(news.aisummary);
    } else{
        setsummary(!user ? "Log in to the OpinionTrade mobile app for swift reading of news content condensed by AI" : "AI is not available, please try again later")
    }
    setloading(false);
  };

  const onClickEnableAI = async () => {
    setloading(true);
    setenableAI(!enableAI);
    fetchLinkData();
  };

  if (news === undefined) return null;

  return (
    
    <AnimatedGradientView
      enabled={enableAI}
      text={summary}
      loading={loading}
      sendSelectedNews={sendSelectedNews}
      isChat={isChat}
    >
      <div
        className={`py-4 border-b sm:px-3 transition hover:bg-[#d0f5ed] ${isRead ? "bg-[#f0f0f0]" : "bg-white"
          } relative ${sendSelectedNews ? "z-[100]" : ""} ${enableAI ? "bg-[#d0f5ed] rounded-t-[10px]" : "rounded-[10px]"
          } ${!enableAI && !sendSelectedNews ? isChat ? "rounded-[10px]" : "rounded-[0px] rounded-l-[10px]" : ""}`}
        key={news.changedNewsTitle}
      >
        <div className="flex flex-col justify-between ml-3 sm:ml-0 mb-2">
          <div
            className={`flex justify-between items-center ${isChat ? "pr-[25px]" : ""
              }`}
          >
            <h3
              className="flex-1 text-md font-semibold pr-2 text-black"
              onClick={() => {
                window.open(
                  `${news.newsLink}`,
                  "_blank",
                  "toolbar=0,location=0,menubar=0,left=500px,top=50px,height=600,width=800"
                );
                if (!isRead) {
                  update(
                    ref(
                      database,
                      `/users/${user.phoneNumber}/readnews/${changedNewsTitle}/`
                    ),
                    news
                  );
                }
              }}
            >
              {news.newsTitle}
            </h3>
            
            <div
              className={`aiButtonDiv flex h-4 ${enableAI
                  ? "bg-gradient-to-r from-cyan-500 to-blue-500"
                  : "bg-gray-200"
                }  justify-center items-center mr-2 py-[13px] px-[10px] rounded-full hover:bg-gradient-to-r from-cyan-500 to-blue-500 transition-all duration-200 group hover:text-white ${isChat ? "absolute top-[10px] right-[0px] cursor-pointer" : ""
                }`}
              onClick={() => onClickEnableAI()}
            >
              <p
                className={`transition-colors font-semibold text-[10px] ${enableAI
                    ? "text-[12px] text-[#fff] font-bold italic"
                    : "text-[#000]"
                  }`}
              >
                AI+
              </p>   
            </div>
            {user && !isChat ? (
              <div
                className={`flex h-4 ${sendSelectedNews ? "bg-[#1ab394] text-white" : "bg-gray-200"
                  } justify-center items-center mr-2 py-[13px] px-[10px] rounded-full hover:bg-[#1ab394] hover:text-white transition-all duration-200`}
                onClick={() => {
                  if (sendSelectedNews) {
                    dispatch({
                      type: actionTypes.SEND_NEWS,
                      sendenabled: false,
                      news: [],
                      room: [],
                    });
                    dispatch({
                      type: actionTypes.SET_COMMENT,
                      comment: "",
                    });
                    dispatch({
                      type: actionTypes.SET_COMMENT,
                      comment: "",
                    });
                  } else {
                    dispatch({
                      type: actionTypes.SEND_NEWS,
                      sendenabled: true,
                      news,
                      room: [],
                    });
                  }
                }}
              >
                {sendSelectedNews && (
                  <div className="flex w-3 h-3 mr-[5px]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="#fff"
                      viewBox="0 0 320 512"
                    >
                      <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                    </svg>
                  </div>
                )}

                <p className="font-semibold text-[10px]">
                  {sendSelectedNews ? "Selected" : "Send"}
                </p>
              </div>
            ) : null}
            {!isChat && (
              <div
                className="flex w-4 h-4"
                onClick={() => {
                  if (isBookmarked) {
                    set(
                      ref(
                        database,
                        `/users/${user.phoneNumber}/bookmarknews/${changedNewsTitle}/`
                      ),
                      null
                    );
                  } else {
                    update(
                      ref(
                        database,
                        `/users/${user.phoneNumber}/bookmarknews/${changedNewsTitle}/`
                      ),
                      {news, addedOn: moment().valueOf() }
                    );
                  }
                }}
              >
                
              </div>
            )}
          </div>

          <div className="text-xs italic text-gray-400 ml-0">
            Source: {news.newsSite}
          </div>
        </div>
        <div className="flex items-center">
          {news.refStockQuote?.length > 0 ? (
            <div className="flex overflow-y-hidden items-center overflow-x-scroll pl-3 sm:pl-0">
              {news.refStockQuote.map((stockArr, index) => {
                return (
                  <NewsStockSlider
                    key={index}
                    stockArr={stockArr}
                    storedsymbols={storedsymbols}
                    index={index}
                  />
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
    </AnimatedGradientView>
  );
}

export default SharedNewsCard;