import { onValue, ref } from "firebase/database";
import moment from "moment";
import { useEffect, useState } from "react";
import { database } from "../../firebase";
import Sidebar from "../Sidebar";
import { useStateValue } from "../../StateProvider";
import NewsCard from "../../components/NewsCard";
import NewsStockSlider from "../../components/NewsStockSlider";
import MarketMovers from "../../components/MarketMovers";
import Header from "../../components/Header";

function BookmarkTab() {
  const [loading, setloading] = useState(true);
  const [news, setnews] = useState([]);
  const [readnews, setreadnews] = useState([]);
  const [bookmarknews, setbookmarknews] = useState([]);
  const [{ user, tab, send, sharednews, selectedstocks }] = useStateValue();
  const [storedsymbols, setstoredsymbols] = useState([]);

  useEffect(() => {
    setloading(true);
    setnews([]);
    getNews();
  }, [tab, selectedstocks]);

  useEffect(() => {
    setloading(true);
    setnews([]);
    if (user) {
      const userInfo = ref(database, `/users/${user.phoneNumber}/`);
      onValue(
        userInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setreadnews([]);
            setbookmarknews([]);
          } else {
            let snapVal = snapshot.val();
            if (
              snapVal.bookmarknews !== undefined &&
              snapVal.bookmarknews !== null
            ) {
              setreadnews(Object.keys(snapVal.readnews));
              setbookmarknews(Object.keys(snapVal.bookmarknews));
            }
          }
        },
        (err) => {
          setreadnews([]);
          setbookmarknews([]);
        }
      );
      const watchlistInfo = ref(
        database,
        `/users/${user.phoneNumber}/watchlist`
      );
      onValue(
        watchlistInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setstoredsymbols([]);
          } else {
            let snapVal = snapshot.val();
            if (snapVal !== undefined && snapVal !== null)
              setstoredsymbols(Object.keys(snapVal));
          }
        },
        (err) => {
          console.log("error getting watchlist", err);
          setstoredsymbols([]);
        }
      );
    } else {
      // dispatch({
      //   type: actionTypes.SET_TAB,
      //   tab: 1,
      // });
      setreadnews([]);
      setbookmarknews([]);
    }
    getNews();
  }, [user]);

  const getNews = () => {
    if (user) {
      const newsInfo = ref(database, `/users/${user.phoneNumber}/`);
      onValue(
        newsInfo,
        (snapshot) => {
          if (!snapshot.exists) {
            setnews([]);
            setloading(false);
          } else {
            let snapVal = snapshot.val();
            if (
              snapVal.bookmarknews !== undefined &&
              snapVal.bookmarknews !== null
            ) {
              let newsVal = Object.values(snapVal.bookmarknews).map(
                (item) => item.news
              );
              newsVal.sort(function (x, y) {
                let a = moment(x.addedOn);
                let b = moment(y.addedOn);
                return b - a;
              });
              setnews(newsVal);
              setloading(false);
            } else {
              setnews([]);
              setloading(false);
            }
          }
        },
        (err) => {
          setnews([]);
          setloading(false);
        }
      );
    }
  };

  if (loading) {
    return (
      <div
        className={`flex flex-col flex-1 overflow-y-scroll scrollbar-hide bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${
          user ? "" : "rounded-r-3xl"
        }`}
      >
        <MarketMovers storedsymbols={storedsymbols} />
        {<Sidebar mobile={true} />}
        <Header
          title="Bookmarked News"
          icon={
            <svg
              height="25px"
              width="25px"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              fill="#0d5446"
            >
              <path d="M48 0H336C362.5 0 384 21.49 384 48V487.7C384 501.1 373.1 512 359.7 512C354.7 512 349.8 510.5 345.7 507.6L192 400L38.28 507.6C34.19 510.5 29.32 512 24.33 512C10.89 512 0 501.1 0 487.7V48C0 21.49 21.49 0 48 0z" />
            </svg>
          }
        />
        {Array(5)
          .fill("")
          .map((_, index) => (
            <div key={index} className="bg-white">
              <div
                role="status"
                className="rounded border-b divide-y divide-gray-200 animate-pulse dark:divide-gray-700 p-3 py-8 dark:border-gray-700"
              >
                <div className="flex justify-between items-center">
                  <div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                    <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-[300px] mb-2.5"></div>
                    <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    );
  }

  return (
    <div
      className={`flex-1 scrollbar bg-white border-r-0 sm:border-r-[5px] cursor-pointer ${
        user ? "" : "sm:rounded-r-3xl"
      } ${send.enabled ? "overflow-y-hidden" : "overflow-y-scroll"}`}
    >
      <MarketMovers storedsymbols={storedsymbols} />
      {<Sidebar mobile={true} />}
      <Header
        title="Bookmarked News"
        icon={
          <svg
            height="25px"
            width="25px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            fill="#0d5446"
          >
            <path d="M48 0H336C362.5 0 384 21.49 384 48V487.7C384 501.1 373.1 512 359.7 512C354.7 512 349.8 510.5 345.7 507.6L192 400L38.28 507.6C34.19 510.5 29.32 512 24.33 512C10.89 512 0 501.1 0 487.7V48C0 21.49 21.49 0 48 0z" />
          </svg>
        }
      />
      <ul className="mt-0 relative">
        {sharednews.newsTitle && (
          <NewsCard
            key={"sharednews"}
            news={sharednews}
            bookmarknews={bookmarknews}
            readnews={readnews}
            storedsymbols={storedsymbols}
          />
        )}
        {news &&
          news.map((news, index) => {
            return (
              <NewsCard
                key={index}
                news={news}
                bookmarknews={bookmarknews}
                readnews={readnews}
                storedsymbols={storedsymbols}
              />
            );
          })}
      </ul>
    </div>
  );
}

export default BookmarkTab;
