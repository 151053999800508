import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useStateValue } from "../../StateProvider";
import NewsCard from "../../components/NewsCard";
import Header from "../../components/Header";
import MarketMovers from "../../components/MarketMovers";

function WatchlistNewsTab() {
  const [{ user, config, selectedstocks }, dispatch] = useStateValue();
  const [news, setnews] = useState([]);
  const [bookmarknews, setbookmarknews] = useState([]);
  const [readnews, setreadnews] = useState([]);
  const [storedsymbols, setstoredsymbols] = useState([]);
  const options = [
    { value: "trending", label: "Trending News" },
    { value: "latest", label: "Latest News" },
    { value: "mostread", label: "Most Read News" },
  ];

  const [selectedOption, setSelectedOption] = useState({
    value: "trending",
    label: "Trending News",
  });

  const handleChange = (option) => {
    setSelectedOption(option);
    console.log("Selected option:", option.value);
    setnews([]);
    if (option.value == "trending") {
      getTrenNews();
    } else if (option.value == "latest") {
      getLatestNews();
    } else if (option.value == "mostread") {
      getReadNews();
    }
  };

  useEffect(() => {
    getTrenNews();
  }, []);

  const getTrenNews = () => {
    // Extract symbols from selectedstocks
    let stocks = selectedstocks.map((stock) => stock.symbol).join("&ticker=");
    let url = `${
      process.env.REACT_APP_SERVER_ADDRESS
    }/opinion/news?sort=refCountTime&pageNumber=0&pageSize=50${
      stocks ? "&ticker=" + stocks : ""
    }`;

    fetch(url)
      .then((resp) => resp.json())
      .then((respJson) => {
        if (respJson.length > 0) {
          setnews(respJson);
        } else {
          setnews([]);
        }
      })
      .catch((error) => {
        setnews([]);
      });
  };

  const getLatestNews = () => {
    // Extract symbols from selectedstocks
    let stocks = selectedstocks.map((stock) => stock.symbol).join("&ticker=");
    let url = `${
      process.env.REACT_APP_SERVER_ADDRESS
    }/opinion/news?sort=pubTime&pageNumber=0&pageSize=50${
      stocks ? "&ticker=" + stocks : ""
    }`;

    fetch(url)
      .then((resp) => resp.json())
      .then((respJson) => {
        if (respJson.length > 0) {
          setnews(respJson);
        } else {
          setnews([]);
        }
      })
      .catch((error) => {
        setnews([]);
      });
  };

  const getReadNews = () => {
    // Extract symbols from selectedstocks
    let stocks = selectedstocks.map((stock) => stock.symbol).join("&ticker=");
    let url = `${
      process.env.REACT_APP_SERVER_ADDRESS
    }/opinion/news?sort=readCount&pageNumber=0&pageSize=50${
      stocks ? "&ticker=" + stocks : ""
    }`;

    fetch(url)
      .then((resp) => resp.json())
      .then((respJson) => {
        if (respJson.length > 0) {
          setnews(respJson);
        } else {
          setnews([]);
        }
      })
      .catch((error) => {
        setnews([]);
      });
  };
  return (
    <div
      className={`flex-1 scrollbar-hide bg-white border-r-0 sm:border-r-[5px] cursor-pointer overflow-y-scroll`}
    >
      <MarketMovers storedsymbols={storedsymbols} />
      <div className="">
        <Header
          title="Watchlist Stocks News"
          icon={
            <svg
              height="25px"
              width="25px"
              fill="#0d5446"
              viewBox="0 0 32.219 32.219"
              className="flex-1"
            >
              <g>
                <path
                  d="M32.144,12.402c-0.493-1.545-3.213-1.898-6.09-2.277c-1.578-0.209-3.373-0.445-3.914-0.844
		c-0.543-0.398-1.304-2.035-1.978-3.482C18.94,3.17,17.786,0.686,16.166,0.68l-0.03-0.003c-1.604,0.027-2.773,2.479-4.016,5.082
		c-0.684,1.439-1.463,3.07-2.005,3.463c-0.551,0.394-2.342,0.613-3.927,0.803c-2.877,0.352-5.598,0.68-6.108,2.217
		c-0.507,1.539,1.48,3.424,3.587,5.424c1.156,1.094,2.465,2.34,2.67,2.98c0.205,0.639-0.143,2.414-0.448,3.977
		c-0.557,2.844-1.084,5.535,0.219,6.5c0.312,0.225,0.704,0.338,1.167,0.328c1.331-0.023,3.247-1.059,5.096-2.062
		c1.387-0.758,2.961-1.611,3.661-1.621c0.675,0.002,2.255,0.881,3.647,1.654c1.891,1.051,3.852,2.139,5.185,2.119
		c0.414-0.01,0.771-0.117,1.06-0.322c1.312-0.947,0.814-3.639,0.285-6.494c-0.289-1.564-0.615-3.344-0.409-3.982
		c0.213-0.639,1.537-1.867,2.702-2.955C30.628,15.808,32.634,13.945,32.144,12.402z M21.473,19.355h-3.722v3.797h-3.237v-3.797
		h-3.768v-3.238h3.768v-3.691h3.237v3.691h3.722V19.355z"
                />
              </g>
            </svg>
          }
        />
        <div className="flex md:ml-4 gap-2 mt-2 md:mt-0 mb-4">
          {options.map((fItem) => {
            let isSelected = fItem.value === selectedOption.value;
            return (
              <div
                key={fItem.value}
                className={`${
                  isSelected ? "bg-[#1c74e9]/10 " : "hover:bg-[#f1f3f4]"
                }px-[8px] py-[2px] rounded-[12px] cursor-pointer`}
                onClick={() => handleChange(fItem)}
              >
                <p
                  className={`${
                    isSelected ? "text-[#185abc]" : "text-[#3c4043]"
                  } text-[.775rem] font-bold`}
                >
                  {fItem.label}
                </p>
              </div>
            );
          })}
        </div>
        <ul className="mt-0 relative">
          {news &&
            news.map((newsItem, index) => (
              <NewsCard
                key={index}
                news={newsItem}
                bookmarknews={bookmarknews}
                readnews={readnews}
                storedsymbols={storedsymbols}
              />
            ))}
        </ul>
      </div>
    </div>
  );
}

export default WatchlistNewsTab;
