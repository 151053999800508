import { createRef, useEffect, useState } from "react";
import { authentication } from "../firebase";
import { useStateValue } from "../StateProvider";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import {
  inMemoryPersistence,
  RecaptchaVerifier,
  setPersistence,
  signInWithPhoneNumber,
} from "firebase/auth";
import { actionTypes } from "../reducer";
import appStoreBadge from "../../src/assets/app_store_badge.svg";
import { ref, set, update, get } from "firebase/database";
import { database } from "../firebase";
import qrCode from "../../src/assets/qr_with_phone.png";
import OTPInput from "otp-input-react";
function Login() {
  const [{ user, chat, loginClicked }, dispatch] = useStateValue();
  const [numberloading, setnumberloading] = useState(false);
  const [codereceived, setcodereceived] = useState(false);
  const [codeloading, setcodeloading] = useState(false);
  const [loginclicked, setloginclicked] = useState(false);
  const [phonenumber, setphonenumber] = useState("");
  const [code, setcode] = useState("");
  const [mostactive, setmostactive] = useState([]);
  const [spy, setspy] = useState({});
  const [dia, setdia] = useState({});
  const [logincount, setLoginCount] = useState(0);
  const [submitcount, setSubmitCount] = useState(0);
  let captcha = createRef();
  const [checked, setChecked] = useState(true);
  const [warning, setWarning] = useState("");

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  // Load count from Firebase when the component mounts
  useEffect(() => {
    const fetchCount = async () => {
      const countRef = ref(database, "/webanalytics/LoginClickCount");
      const snapshot = await get(countRef);

      if (snapshot.exists()) {
        setLoginCount(snapshot.val());
      } else {
        console.log("Count does not exist in Firebase.");
      }
    };

    fetchCount();
  }, []);

  console.log('logincount', logincount)

  // Load count from Firebase when the component mounts
  useEffect(() => {
    const fetchCount = async () => {
      const countRef = ref(database, "/webanalytics/SubmitClickCount");
      const snapshot = await get(countRef);

      if (snapshot.exists()) {
        setSubmitCount(snapshot.val());
      } else {
        console.log("Count does not exist in Firebase.");
      }
    };

    fetchCount();
  }, []);

  console.log('submitcount', submitcount)

  useEffect(() => {
    getMostActive();
    getSPY();
    getDIA();
  }, []);

  const getMostActive = () => {
    fetch(`${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/mostactive`)
      .then((data) => data.json())
      .then((datajson) => {
        setmostactive(datajson);
      });
  };

  const getSPY = () => {
    fetch(
      `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/getStockQuote?ticker=SPY`
    )
      .then((data) => data.json())
      .then((datajson) => {
        setspy(datajson);
      });
  };

  const getDIA = () => {
    fetch(
      `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/getStockQuote?ticker=DIA`
    )
      .then((data) => data.json())
      .then((datajson) => {
        setdia(datajson);
      });
  };

  
  const signIn = () => {
    setnumberloading(true);
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
      },
      authentication
    );
    onSignInSubmit();
  };

  const onSignInSubmit = async () => {
    const appVerifier = window.recaptchaVerifier;
    const number = `+${phonenumber}`;
    /*
    const userRef = ref(database, `/users/${number}`); 
    const snapshot = await get(userRef);
    if (!snapshot.exists()) {
      const isAndroid = /Android/i.test(navigator.userAgent);
      const message = `Download OpinionTrade \n\n - Shared By OpinionTrade App\n\nhttp://web.opiniontrade.ai/share/http://www.opiniontrade.ai`
      const smsUri = isAndroid ? `sms:${number}?body=${encodeURIComponent(message)}` : `sms:/open?addresses=${number}&body=${encodeURIComponent(message)}`
      window.open(smsUri, '_self'); 
    }else{
      console.log("User already exists in Firebase, no SMS sent.");
    }
    */
    setPersistence(authentication, inMemoryPersistence)
      .then(() => {
        return signInWithPhoneNumber(authentication, number, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            // Use update to modify only specific fields
            update(ref(database, `/users/${number}/UserStateMachine`), {
              EnteredPhoneNumberonWeb: true,
            });

            update(ref(database, `/users/${number}`), {
              createdAt: new Date().toISOString(),
              WebOTPInput: false,
            });
            setcodereceived(true);
          })
          .catch((error) => {
            console.log("error", error);
          });
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  const onVerifyCodeSubmit = (event) => {
    let confirmationResult = window.confirmationResult;
    setcodeloading(true);
    setPersistence(authentication, inMemoryPersistence)
      .then(() => {
        return confirmationResult
          .confirm(code)
          .then((result) => {
            const user = result.user;
            console.log("log user", user);
            if (user) {
              // Use update to modify specific fields within UserStateMachine
              update(ref(database, `/users/${user.phoneNumber}/UserStateMachine`), {
                LoggedinOnWeb: true,
                ConvertedLoggedinOnWebByAffilitaedMarketing: false,
              });
              update(ref(database, `/users/${user.phoneNumber}`), {
                WebOTPInput: true,
                groupMessage: true,
                groupNewsCard: true,
                peerMessage: true,
                peerNewsCard: true,
                sound: true,
              })
                .then(() => {
                  console.log("User settings updated successfully!");
                })
                .catch((error) => {
                  console.error("Error updating user settings:", error);
                });
              dispatch({
                type: actionTypes.SET_USER,
                user: user.toJSON(),
              });
              localStorage.setItem("user", JSON.stringify(user));
            }
          })
          .catch((error) => {
            console.error("Error while checking the verification code", error);
          });
      })
      .catch((error) => {
        console.log("err", error);
      });
  };

  const renderSPY = () => {
    let changePercent = (spy.changePercent * 100).toFixed(2);
    let isUp = changePercent > 0;
    return (
      <li
        className={`flex flex-col transition ${
          isUp ? "bg-[#fff]" : "bg-[#fff]"
        } rounded-md mb-3 group mr-2 flex-1 p-2 w-[156px] h-[130px] border-[1px] border-[#dadce0]`}
      >
        <div className="flex flex-1 flex-col justify-start items-start">
          <div
            className={`text-xs text-white ${
              isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
            } rounded-[5px] px-2 py-1 font-bold`}
          >
            <p className={`${isUp ? "text-[#137333]" : "text-[#c5221f]"}`}>
              {spy.symbol}
            </p>
          </div>
          <h3 className={`text-[.875rem] font-semibold text-[#000] mr-3`}>
            S&P 500
          </h3>
        </div>
        <div className="flex flex-1 flex-col justify-end items-start mt-auto">
          <p className="font-bold text-[1rem] text-black">
            ${((spy.latestPrice * 10).toFixed(2))}
          </p>
          <div
            className={`rounded-[8px] px-[8px] ${
              isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
            }`}
          >
            <p
              className={`text-[16px] font-bold tracking-[.00625em] ${
                isUp ? "text-[#137333]" : "text-[#a50e0f]"
              }`}
            >
              {changePercent}%
            </p>
          </div>
        </div>
      </li>
    );
  };

  const renderDIA = () => {
    let changePercent = (dia.changePercent * 100).toFixed(2);
    let isUp = changePercent > 0;
    return (
      <li
        className={`flex flex-col transition bg-[#fff] rounded-md mb-3 group flex-1 p-2 w-[156px] h-[130px] border-[1px] border-[#dadce0]`}
      >
        <div className="flex flex-1 flex-col justify-start items-start">
          <div
            className={`text-xs text-white ${
              isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
            } rounded-[5px] px-2 py-1 font-bold`}
          >
            <p className={`${isUp ? "text-[#137333]" : "text-[#c5221f]"}`}>
              {dia.symbol}
            </p>
          </div>
          <h3 className="text-[.875rem] font-semibold text-black mr-3">
            Dow Jones
          </h3>
        </div>
        <div className="flex flex-1 flex-col justify-end items-start">
          <p className="font-bold text-[1rem] text-black">
            ${(dia.latestPrice * 100).toFixed()}
          </p>
          <div
            className={`rounded-[8px] px-[8px] ${
              isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
            }`}
          >
            <p
              className={`text-[16px] font-bold tracking-[.00625em] ${
                isUp ? "text-[#137333]" : "text-[#a50e0f]"
              }`}
            >
              {changePercent}%
            </p>
          </div>
        </div>
      </li>
    );
  };

  console.log('loginclickyyy', loginClicked);

  useEffect(() => {
  if (loginClicked === true) {
    setloginclicked(loginClicked);
  }
},);

  return (
    <div
      className={`sm:flex hidden w-full px-8 ${
        loginclicked ? "w-[500px] justify-center items-center" : "w-[300px]"
      } transition-width transition-slowest ease`}
    >
      <div ref={(ref) => (captcha = ref)}>
        <div id="recaptcha-container"></div>
      </div>
      {loginclicked ? (
        codereceived ? (
          <div className="flex flex-col justify-start">
            <div
              className="h-16 w-16 rounded-full overflow-hidden realtive flex justify-center p-3 cursor-pointer absolute right-[10px] top-[10px]"
              onClick={() => {
                setloginclicked(false);
                setcodereceived(false);
                setcodereceived(false);
                setnumberloading(false);
                setphonenumber("");
                const appVerifier = window.recaptchaVerifier;
                window.recaptchaVerifier = appVerifier.clear();
                captcha.innerHTML = '<div id="recaptcha-container"></div>';
                window.confirmationResult = "";
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                fill="#fff"
              >
                <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
              </svg>
            </div>
            <div className="flex flex-col items-center justify-center h-screen -mt-[-100]">
            <h1 className="font-bold text-3xl text-black mb-6 ">Enter OTP</h1>
            <OTPInput
              value={code}
              onChange={setcode}
              autoFocus
              OTPLength={6}
              otpType="number"
              disabled={false}
              inputStyles={{
                width: 50,
                height: 50,
                borderRadius: 5,
                fontSize: 24,
              }}
            />
            <button
              className="bg-[#1ab394] text-white px-6 py-2 rounded-xl mt-4 font-bold "
              onClick={() => onVerifyCodeSubmit()}
            >
              {codeloading ? (
                <div role="status" className="flex justify-center">
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Join"
              )}
            </button>
            </div>
          </div>
        ) : (
          <div className="flex flex-col mt-[-600px]">
            <div
              className="h-16 w-16 rounded-full overflow-hidden realtive flex justify-center p-3 cursor-pointer absolute right-[10px] top-[10px]"
              onClick={() => {
                dispatch({
                  type: actionTypes.SET_LOGIN_CLICKED,
                  loginClicked: false,
                });
                setloginclicked(false);
                setcodereceived(false);
                setcodereceived(false);
                setnumberloading(false);
                setphonenumber("");
                const appVerifier = window.recaptchaVerifier;
                window.recaptchaVerifier = appVerifier.clear();
                captcha.innerHTML = '<div id="recaptcha-container"></div>';
                window.confirmationResult = "";
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                fill="#fff"
              >
                <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
              </svg>
            </div>
            <h1 className="font-bold text-xl text-black mb-6">Enter Your Phone (USA only)</h1>
            <PhoneInput
              country={"us"}
              onlyCountries={["us"]}
              value={phonenumber}
              onChange={(phone) => setphonenumber(phone)}
              inputStyle={{
                flex: 1,
                backgroundColor: numberloading ? "#cacaca" : "#ffffff",
              }}
              disabled={numberloading}
            />
            <button
              className="bg-[#1ab394] text-white px-6 py-2 rounded-xl mt-4 font-bold"
              onClick={() => {
                //setSubmitCount(0)
                
                setSubmitCount(prevCount => {
                  const newCount = prevCount + 1;
              
                  // Update Firebase with the new count
                  update(ref(database, `/webanalytics/`), { SubmitClickCount: newCount })
                  .then(() => {
                    console.log('Firebase updated successfully');
                  })
                  .catch(error => {
                    console.error('Error updating Firebase:', error);
                  });
              
                  return newCount; // Ensure the state is updated correctly
                });
                if (checked){
                  setWarning("");
                  signIn()
                }else{
                  setWarning("Please accept the Terms and Conditions before submitting.");
                }
              }}
            >
              {numberloading ? (
                <div role="status" className="flex justify-center">
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor"
                    />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill"
                    />
                  </svg>
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                "Submit"
              )}
            </button>
            {warning && <p style={{ color: "red", fontSize: "14px" }}>{warning}</p>}
            <div style={{ display: "flex", alignItems: "center", marginTop: 8 }}>
            <input
              type="checkbox"
              checked={checked}
              onChange={handleCheckboxChange}
              style={{ marginRight: "8px" }}
            />
            <span>
              I accept the{" "}
              <a
                href="https://www.opiniontrade.ai/termsandcondition.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#007bff", textDecoration: "none" }}
              >
                Terms and Conditions
              </a>
              .
          </span>
          </div>
          </div>
        )
      ) : (
        <div className="flex flex-col w-full">
          <div className="flex flex-1 flex-col">
            <div className="flex mb-3 mt-[20px]">
                {renderSPY()}
                {renderDIA()}
            </div>
            <div className="border-t-[2px] border-[#e5e7eb] ">
            <p className="font-handwritten text-[25px] text-black pt-2 text-center ">
              Download our app
            </p>
            <div className="flex">
              <div className="flex flex-1 justify-center items-center space-x-4">
                <a
                  href="https://apps.apple.com/us/app/opiniontrade/id1530699357"
                  target="_blank"
                >
                  <img
                    src={qrCode}
                    loading="lazy"
                    style={{
                      width: "200px",
                      height: "200px",
                    }}
                    className="h-auto w-[250px] object-contain cursor-pointer"
                  />
                </a>
              </div>
            </div>
          </div>
            <div className="flex flex-1 flex-col justify-center items-center mb-6 mt-[-300px]">
              <button
                className="bg-[#1ab394] text-white px-6 py-2 rounded-xl mt-4 font-bold"
                onClick={() => {
                  //setLoginCount(0)
                  
                  setLoginCount(prevCount => {
                    const newCount = prevCount + 1;
                
                    // Update Firebase with the new count
                    update(ref(database, `/webanalytics/`), { LoginClickCount: newCount })
                      .then(() => {
                        console.log('Firebase updated successfully');
                      })
                      .catch(error => {
                        console.error('Error updating Firebase:', error);
                      });
                
                    return newCount; // Ensure the state is updated correctly
                  });
                  
                  setloginclicked(true)
                  }
                }
              >
                Login
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
