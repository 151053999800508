import { actionTypes } from "../reducer";
import { useStateValue } from "../StateProvider";

function Header({ title, icon }) {
  const [{ chat, selectedstocks, config }, dispatch] = useStateValue();
  return (
    <div className="flex flex-col sticky top-[112px] z-50 bg-white">
      <div
        className={`flex w-full items-center justify-start ${
          chat.enabled
            ? "flex-col py-6"
            : "flex-col sm:flex-row h-[120px] sm:h-auto"
        }`}
      >
        <div
          className={`flex flex-1 h-[70px] w-full sticky top-0 z-50 items-center ${
            chat.enabled
              ? "justify-center mb-[15px]"
              : "pl-0 sm:pl-4 justify-center sm:justify-start"
          }`}
        >
          <div className="mt-1 mr-2">{icon}</div>
          <h1
            className="font-bold text-2xl text-black mt-1"
            style={{
              color:
                config.typeofFirebaseDB !== "production"
                  ? "#d54446"
                  : "#0d5446",
            }}
          >
            {title}
          </h1>
        </div>
      </div>
      {selectedstocks && selectedstocks.length > 0 && (
        <div className="flex px-3 pb-2 flex-wrap">
          {selectedstocks.map((stock) => {
            return (
              <div
                className="flex flex-row justify-center items-center p-2 py-1 pl-4 pr-[8px] rounded-full border cursor-pointer hover:bg-[#e5e7eb] mr-2 mb-[5px]"
                onClick={() => {
                  dispatch({
                    type: actionTypes.DELETE_SELECTED_STOCK,
                    stock: stock,
                  });
                }}
              >
                <p className="text-sm">
                  {stock.name ? stock.name : stock.companyName}
                </p>
                <div className="w-3 h-3 flex ml-[10px]">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path d="M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z" />
                  </svg>
                </div>
              </div>
            );
          })}
          {
            <div
              className="flex justify-center items-center ml-4 mb-[5px]"
              onClick={() => {
                dispatch({
                  type: actionTypes.CLEAR_SELECTED_STOCK,
                });
              }}
            >
              <p className="text-[12px]">Clear All</p>
            </div>
          }
        </div>
      )}
    </div>
  );
}

export default Header;
