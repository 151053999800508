import "./styles/NewsStockSlider.css";
import { ref, set, get } from "firebase/database";
import { database } from "../firebase";
import { actionTypes } from "../reducer";
import { useStateValue } from "../StateProvider";
import Tooltip from "@mui/material/Tooltip";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { useState } from "react";

function MarketMoversStockSlider({
  stockArr,
  storedsymbols = [],
  index,
  watchlistCheck,
}) {
  const [{ user, config, selectedstocks, tab }, dispatch] = useStateValue();
  const changePercent = stockArr.changePercent;
  const changePercent100 = stockArr.changePercent * 100;
  let earlierPrice = stockArr.latestPrice / (1 + changePercent);
  let priceDiff = earlierPrice - stockArr.latestPrice;
  const isUp = changePercent > 0;
  const [isAddedToWatchlist, setisAddedToWatchlist] = useState(false);
  const theme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltipArrow: {
            backgroundColor: "transparent",
            boxShadow:
              "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;",
            padding: 0,
            borderRadius: 10,
            minWidth: 250,
          },
        },
      },
    },
  });

  const addStock = (stock) => {
    if (selectedstocks.includes(stock)) {
    } else {
      dispatch({
        type: actionTypes.SET_SELECTED_STOCK,
        stock: stock,
      });
      setloading(true);
    }
  };

  if (stockArr === undefined) return null;

  const getNews = (stock) => {
    console.log("stockky wockyyy", stock);
    dispatch({
      type: actionTypes.SET_TAB,
      tab: 5,
    });
    dispatch({
      type: actionTypes.SET_SELECTED_STOCK,
      stock: stock,
    });
  };

  return (
    <div key={index}>
      <ThemeProvider theme={theme}>
        <Tooltip
          title={
            <>
              <div
                className="pop_card rounded-t-[10px]"
                style={{
                  display: "flex",
                  padding: 10,
                  alignItems: "center",
                  backgroundColor: isUp ? "#1ab394" : "#f44336",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingRight: 0,
                }}
              >
                <p
                  style={{
                    color: "#fff",
                    fontSize: 18,
                    fontWeight: "600",
                  }}
                >
                  {stockArr.symbol}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: isUp ? "#1ab394" : "#f44336",
                    padding: 5,
                    // borderRadius: 10,
                    alignItems: "center",
                  }}
                >
                  <i
                    className={`fas ${
                      isUp ? "fa-arrow-circle-up" : "fa-arrow-circle-up"
                    }`}
                    style={{ marginRight: 3 }}
                  ></i>
                  <p
                    style={{
                      fontSize: 16,
                      color: "#fff",
                    }}
                  >
                    {" "}
                    {changePercent100.toFixed(2)}%{" "}
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  padding: 10,
                  backgroundColor: isUp ? "#1ab394" : "#f44336",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingTop: 0,
                }}
              >
                <div
                  style={{
                    flex: 1,
                    marginRight: 20,
                  }}
                >
                  <p
                    style={{
                      color: "#fff",
                      fontSize: 14,
                    }}
                  >
                    {stockArr.companyName}
                  </p>
                </div>
                <div
                  style={{
                    alignItems: "flex-end",
                  }}
                >
                  <p
                    style={{
                      color: "#fff",
                      fontSize: 14,
                    }}
                  >
                    ${stockArr.latestPrice}
                  </p>
                </div>
              </div>
              <div className="bg-white rounded-b-[10px]">
                {!watchlistCheck ? (
                  <div
                    style={{
                      padding: 10,
                      paddingVertical: 15,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className={`cursor-pointer ${
                      user ? "hidden sm:flex" : "hidden"
                    } ${isAddedToWatchlist ? "bg-gray-100" : ""}`}
                    onClick={async () => {
                      setisAddedToWatchlist(
                        storedsymbols.includes(stockArr.symbol)
                      );
                      if (isAddedToWatchlist) {
                        const userWatchlistPath = `/users/${user.phoneNumber}/watchlist`;
                        // Get the current watchlist
                        const watchlistRef = ref(database, userWatchlistPath);
                        get(watchlistRef).then((snapshot) => {
                          const watchlist = snapshot.val();

                          // If the watchlist exists and is an array, filter out the stock with the matching symbol
                          if (watchlist && Array.isArray(watchlist)) {
                            const updatedWatchlist = watchlist.filter(
                              (item) => Object.keys(item)[0] !== stockArr.symbol
                            );

                            // Update the watchlist in Firebase after removing the stock
                            return set(watchlistRef, updatedWatchlist);
                          } else {
                            console.log(
                              "Watchlist is either empty or not in expected format"
                            );
                          }
                        });
                        setisAddedToWatchlist(false);
                      } else {
                        dispatch({
                          type: actionTypes.SET_TAB,
                          tab: 5,
                        });
                        const response = await fetch(
                          `${process.env.REACT_APP_SERVER_ADDRESS}/opinion/v1/getStockQuote?ticker=${stockArr.symbol}`
                        );

                        const dataJson = await response.json();
                        const userWatchlistPath = `/users/${user.phoneNumber}/watchlist`;
                        // Get the current watchlist
                        const watchlistRef = ref(database, userWatchlistPath);
                        get(watchlistRef).then(async (snapshot) => {
                          let watchlist = [];
                          let updatedWatchlist = [];
                          if (snapshot.exists()) {
                            watchlist = snapshot.val();
                          } else {
                            watchlist = [];
                          }
                          // If the watchlist exists and is an array, filter out the stock with the matching symbol
                          if (watchlist && Array.isArray(watchlist)) {
                            // Add new stock to the watchlist
                            const stockExists = watchlist.some(
                              (item) =>
                                item[stockArr.symbol] &&
                                item[stockArr.symbol].added === true
                            );

                            if (!stockExists) {
                              const newStock = {
                                [stockArr.symbol]: {
                                  ...dataJson,
                                  index: watchlist.length, // Use the current length for index
                                  enabled: true,
                                  added: true,
                                },
                              };
                              watchlist.push(newStock);
                              // Update the watchlist in Firebase after removing the stock
                              return await set(watchlistRef, watchlist);
                            } else {
                              setisAddedToWatchlist(true);
                            }
                          } else {
                            console.log(
                              "Watchlist is either empty or not in expected format"
                            );
                          }
                        });
                        setisAddedToWatchlist(true);
                      }
                    }}
                  >
                    {isAddedToWatchlist ? (
                      <p className="text-[12px] text-[#5b5b5b] font-bold">
                        Remove {stockArr.symbol} from Watchlist
                      </p>
                    ) : (
                      <p className="cursor-pointer text-[12px] text-[#5b5b5b]">
                        Add {stockArr.symbol} to Watchlist
                      </p>
                    )}
                    <i
                      className={"fas fa-chevron-right"}
                      style={{ color: "#5b5b5b" }}
                    ></i>
                  </div>
                ) : null}
                {}
                {watchlistCheck ? (
                  <div
                    style={{
                      borderTopWidth: 1,
                      borderTopColor: "#f2f2f2",
                      padding: 10,
                      paddingVertical: 15,
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className={`flex cursor-pointer`}
                    onClick={() => {
                      dispatch({
                        type: actionTypes.SET_TAB,
                        tab: 5,
                      });
                      getNews(stockArr);
                    }}
                  >
                    <p
                      style={{
                        color: "#5b5b5b",
                        fontSize: 12,
                      }}
                    >
                      Watchlist News for {stockArr.symbol}
                    </p>
                    <i
                      className={"fas fa-chevron-right"}
                      style={{ color: "#5b5b5b" }}
                    ></i>
                  </div>
                ) : null}

                <div
                  style={{
                    borderTopWidth: 1,
                    borderTopColor: "#f2f2f2",
                    padding: 10,
                    paddingVertical: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className={`flex cursor-pointer`}
                  onClick={() => {
                    dispatch({
                      type: actionTypes.SET_TAB,
                      tab: 1,
                    });
                    addStock(stockArr);
                  }}
                >
                  <p
                    style={{
                      color: "#5b5b5b",
                      fontSize: 12,
                    }}
                  >
                    Trending News for {stockArr.symbol}
                  </p>
                  <i
                    className={"fas fa-chevron-right"}
                    style={{ color: "#5b5b5b" }}
                  ></i>
                </div>
                <div
                  style={{
                    borderTopWidth: 1,
                    borderTopColor: "#f2f2f2",
                    padding: 10,
                    paddingVertical: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className={`flex cursor-pointer`}
                  onClick={() => {
                    dispatch({
                      type: actionTypes.SET_TAB,
                      tab: 2,
                    });
                    addStock(stockArr);
                  }}
                >
                  <p
                    style={{
                      color: "#5b5b5b",
                      fontSize: 12,
                    }}
                  >
                    Latest News for {stockArr.symbol}
                  </p>
                  <i
                    className={"fas fa-chevron-right"}
                    style={{ color: "#5b5b5b" }}
                  ></i>
                </div>
                <div
                  style={{
                    borderTopWidth: 1,
                    borderTopColor: "#f2f2f2",
                    padding: 10,
                    paddingVertical: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className={`flex cursor-pointer`}
                  onClick={() => {
                    dispatch({
                      type: actionTypes.SET_TAB,
                      tab: 3,
                    });
                    addStock(stockArr);
                  }}
                >
                  <p
                    style={{
                      color: "#5b5b5b",
                      fontSize: 12,
                    }}
                  >
                    Most Read News for {stockArr.symbol}
                  </p>
                  <i
                    className={"fas fa-chevron-right"}
                    style={{ color: "#5b5b5b" }}
                  ></i>
                </div>
                <div
                  style={{
                    borderTopWidth: 1,
                    borderTopColor: "#f2f2f2",
                    padding: 10,
                    paddingVertical: 15,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className={`flex cursor-pointer`}
                  onClick={() => {
                    window.open(
                      `${config.watchlistLaunchUrlPre}${stockArr.symbol}${config.watchlistLaunchUrlPost}`,
                      "_blank",
                      "toolbar=0,location=0,menubar=0,left=500px,top=50px,height=600,width=800"
                    );
                  }}
                >
                  <p
                    style={{
                      color: "#5b5b5b",
                      fontSize: 12,
                    }}
                  >
                    {config.stockPopupMessagePre}
                    {stockArr.symbol}
                    {config.stockPopupMessagePost}
                  </p>
                  <i
                    className={"fas fa-chevron-right"}
                    style={{ color: "#5b5b5b" }}
                  ></i>
                </div>
              </div>
            </>
          }
          placement="top"
          className="my-tooltip"
          arrow
        >
          <div
            key={index}
            data-tip="custom theme"
            data-for={stockArr.symbol}
            data-event="click focus"
            data-event-off="dblclick"
          >
            <div
              className={`flex border-[1px] border-[#dadce0] p-[6px] items-center rounded-[8px] bg-white hover:z-100 gap-1`}
            >
              <div
                className={`flex mr-[8px] ${
                  isUp ? "bg-[#e6f4ea]" : "bg-[#fce8e6]"
                } w-[32px] h-[32px] min-w-[32px] justify-center items-center rounded-[8px]`}
              >
                {isUp ? (
                  <svg
                    focusable="false"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="#137333"
                  >
                    <path d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"></path>
                  </svg>
                ) : (
                  <svg
                    focusable="false"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="#c5221f"
                  >
                    <path d="M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8 8-8z"></path>
                  </svg>
                )}
              </div>
              <div className="flex gap-3">
                <div className="flex flex-col items-start">
                  <p className="font-bold text-[11px] text-black">
                    {stockArr.symbol}
                  </p>
                  <p className="font-normal text-[11px] text-black">
                    {stockArr.latestPrice}
                  </p>
                </div>
                <div className="flex flex-col items-end">
                  <p
                    className={`font-bold text-[11px] ${
                      isUp ? "text-[#137333]" : "text-[#c5221f]"
                    }`}
                  >
                    {isUp ? "+" : ""}
                    {changePercent100.toFixed(2)}%
                  </p>
                  <p
                    className={`font-bold text-[11px] ${
                      isUp ? "text-[#137333]" : "text-[#c5221f]"
                    }`}
                  >
                    {isUp ? "+" : ""}
                    {(-1 * priceDiff).toFixed(2)}
                  </p>
                </div>
              </div>

              {config && (
                <div
                  className={`flex w-6 h-6 ml-[6px] justify-center items-center rounded-full`}
                  onClick={() => {
                    window.open(
                      `${config.watchlistLaunchUrlPre}${stockArr.symbol}${config.watchlistLaunchUrlPost}`,
                      "_blank",
                      "toolbar=0,location=0,menubar=0,left=300px,top=50px,height=600,width=1100"
                    );
                  }}
                >
                  <img
                    className="flex-1 rounded-full"
                    src={config.stockImageURI2}
                  />
                </div>
              )}
            </div>
          </div>
        </Tooltip>
      </ThemeProvider>
    </div>
  );
}

export default MarketMoversStockSlider;
